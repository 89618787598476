<template>
    <div v-if="!provider">
        <v-skeleton-loader v-show="!provider" :loading="!provider" type="image" />
        <v-skeleton-loader v-show="!provider" :loading="!provider" type="heading" />
        <v-skeleton-loader v-show="!provider" :loading="!provider" type="button" />
    </div>
    <div v-else>
        <h1>Api Setup</h1>
        <child-layout :title="apiName" class="sis-api__overview-layout">
            <template v-slot:actions>
                <sis-import-provider-form-modal ref="sisImportModal" :provider.sync="provider">
                    <template v-slot:default="{ on, attrs }">
                        <v-icon :disabled="!canEditApi" v-bind="attrs" v-on="on">
                            {{ 'fal fa-pencil' }}
                        </v-icon>
                    </template>
                </sis-import-provider-form-modal>
            </template>
            <v-row class="my-5" align="center">
                <v-col cols="auto">
                    <v-img
                        :src="providerLogoSrc"
                        :alt="`${provider.name} Logo`"
                        max-height="60"
                        contain
                    />
                </v-col>
                <v-col>
                    <div class="child-layout__label">{{ `${provider.name} Domain` }}</div>
                    <div class="child-layout__info">
                        {{ provider.sisImportConfig.sisApiUrl }}
                    </div>
                </v-col>
            </v-row>
        </child-layout>
    </div>
</template>
<script>
import { mapActions, mapGetters } from 'vuex'
import SisImportProviderFormModal from '@/views/DistrictManagement/SisImportApi/ApiConfigDialog'
import ChildLayout from '@/components/layout/ChildLayout'
import { ROLE_SETUP_SIS_IMPORTS_API_EDIT } from '@/helpers/security/roles'

export default {
    name: 'SisImportProviderOverviewForClient',
    components: {
        ChildLayout,
        SisImportProviderFormModal,
    },
    data: function () {
        return {
            valid: false,
        }
    },
    computed: {
        ...mapGetters({
            districtId: 'getDistrictId',
            districtLea: 'getDistrictLea',
            sisImportProviders: 'sisSystem/getItems',
            sisImportConfig: 'sisApiConfig/getItems',
            hasRole: 'auth/hasRole',
        }),
        apiName() {
            return `${this.provider.name} API`
        },
        providerLogoSrc() {
            if (this.provider?.name !== null && this.provider?.name !== undefined) {
                const images = require.context('@/assets/sis-api-logos/', false, /\.png$/)

                try {
                    return images('./Logo-' + this.provider.name + '.png')
                } catch {
                    return images('./Logo-Plug.png')
                }
            } else {
                return ''
            }
        },
        canEditApi() {
            return this.hasRole(ROLE_SETUP_SIS_IMPORTS_API_EDIT)
        },
        provider() {
            // return null until the sisImportConfig and sisImport provider are loaded.
            if (
                this.sisImportConfig.length < 1 ||
                this.sisImportProviders.length < 1 ||
                this.sisImportConfig[0].cfgSisSystemId === null
            ) {
                return null
            }
            const provider = {
                sisImportConfig: this.sisImportConfig[0],
            }

            return {
                ...provider,
                name:
                    this.sisImportProviders.find(
                        (p) => p.id == this.sisImportConfig[0].cfgSisSystemId
                    )?.name ?? '',
            }
        },
    },
    watch: {
        async districtLea() {
            await this.getConfig({
                id: this.districtId,
                lea: this.districtLea,
            })
        },
    },
    async mounted() {
        if (this.sisImportProviders.length < 1) {
            this.getSisImportProviders()
        }

        if (this.districtLea) {
            await this.getConfig({
                id: this.districtId,
                lea: this.districtLea,
            })
        }
    },
    updated() {
        /*
         * Show the modal immediately if the the query param 'm' is set to something truthy
         *
         * Note - This check is in the updated event because the sisImportModal component is not displayed until
         * after the showLoader stuff is finished so this.$refs.sisImportModal is undefined
         */
        if (this.$route.query?.m) {
            this.$refs.sisImportModal.opened = true
        }
    },
    methods: {
        ...mapActions({
            getConfig: 'sisApiConfig/get',
            getSisImportProviders: 'sisSystem/get',
        }),
    },
}
</script>
<style lang="scss" scoped>
.v-skeleton-loader {
    padding-bottom: 35px;

    &::v-deep .v-skeleton-loader__sentences:not(:last-of-type) {
        padding-bottom: 35px;
    }
}

.sis-api__overview-layout {
    .col:first-of-type {
        padding-right: 0;
    }
}
</style>
